import React from "react"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

const StyledLink = styled(Link)`
  ${tw`inline-flex items-center cursor-pointer px-4 py-6 md:p-6 bg-purple flex justify-center w-full md:w-auto`}
`

const BackIcon = styled(FontAwesomeIcon)`
  ${tw`mr-2 text-white md:text-lg`}
`

const Text = styled.span`
  ${tw`no-underline text-base md:text-2xl font-semibold text-white font-header leading-normal`}
  line-height: 1.45;
`

const Back = ({ state }) => (
  <StyledLink className="hvr-sweep-to-right-purple" to={"/"} state={state}>
    <BackIcon icon={faArrowLeft} />
    <div>
      <Text>Back</Text>
    </div>
  </StyledLink>
)

export default Back
