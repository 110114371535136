import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { get } from "lodash"
import tw, { styled } from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { css } from "styled-components"

import WorkNav from "../components/WorkNav"

import underline from "../../static/assets/purple-line.svg"
import berries from "../../static/assets/berries.svg"
import favicon from "../images/favicon.png"

import Back from "../components/Back"

const Header = styled.header`
  ${tw`relative`}
`

const HeaderImage = styled.img`
  height: 400px;
  object-fit: cover;
  width: 100%;

  @media (max-width: 767px) {
    height: 200px;
  }
`

const Container = styled.main`
  ${tw`py-10 px-6 md:py-26 max-w-screen-lg my-0 mx-auto relative`}
`

const TitleContainer = styled.div`
  ${tw`relative`}
`

const Title = styled.h1`
  ${tw`font-header text-xl text-default md:text-title leading-normal`}
`

const Underline = styled.img`
  width: 320px;
  position: absolute;
  bottom: -7px;

  @media (max-width: 767px) {
    width: 185px;
  }
`

const TagContainer = styled.div`
  ${tw`flex flex-wrap mt-5 md:mt-8`}
`

const Tag = styled.p`
  ${tw`text-base font-content italic mr-5 md:text-2xl font-semibold`}
`

const Content = styled.div`
  ${tw`font-content text-base text-default md:text-2xl leading-tight mt-5 md:mb-20 mb-8`}

  h2,h3 {
    ${tw`font-header font-semibold text-base md:text-3xl leading-normal mt-4 mb-3 inline-block`}
  }

  a {
    ${tw`underline text-default`}

    &:hover {
      ${tw`text-purple`}
    }
  }

  ul {
    ${tw`list-inside`}
  }

  li {
    ${tw`text-base md:text-2xl leading-7 md:leading-loose pl-1 md:pl-2`}

    &:before {
      background-color: #6480db;
      border-radius: 50%;
      content: "";
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 2px;
      height: 10px;
      width: 10px;

      @media (max-width: 767px) {
        height: 6px;
        width: 6px;
      }
    }
  }

  p {
    ${tw`mb-4`}
  }

  img {
    ${tw`mb-10 my-0 mx-auto`}

    @media (min-width: 1024px) {
      max-width: 800px;
    }

    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  strong {
    ${tw`font-semibold text-purple`}
  }

  div {
    ${tw`flex justify-center mb-10 md:mb-15`}

    iframe {
      ${tw`md:w-3/5`}

      @media (min-width: 768px) {
        height: 325px;
      }
    }
  }

  pre {
    ${tw`italic whitespace-pre-wrap leading-tight font-semibold text-2xl md:text-title md:mt-8 md:mb-14 mt-4 mb-10 relative`}

    &:before {
      content: "";
      background-image: url("./assets/line-small.svg");
      position: absolute;
      width: 60px;
      height: 4px;
      background-repeat: no-repeat;
      bottom: -20px;

      @media (max-width: 767px) {
        bottom: -15px;
      }
    }
  }
`

const Links = styled.div`
  ${tw`flex flex-wrap`}
`

const LinkTitle = styled.h2`
  ${tw`font-header text-default text-base md:text-3xl leading-normal block mb-5 md:mb-6 font-semibold`}
`

const ExternalLink = styled.a`
  ${tw`font-content text-default box-border pb-2 border-solid border-b border-black mr-4 mb-4 md:mr-8 text-base md:text-2xl`}

  &:hover {
    ${tw`text-purple border-purple`}
  }
`

const Icon = styled(FontAwesomeIcon)`
  ${tw`text-xs align-middle`}
`

const BackContainer = styled.div`
  ${tw`flex md:absolute`}
`

const ColoredHeader = styled.div`
  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
      height: 500px;
      width: 100%;

      @media (max-width: 767px) {
        height: 200px;
      }
    `}
`

const Berries = styled.img`
  ${tw`hidden md:flex`}
  position: absolute;
  transform: rotateX(180deg) rotateY(180deg);
  height: 130px;
  bottom: -70px;
  right: 120px;
`

const Work = ({
  pageContext: { workNav },
  data: { prismicWork },
  location,
}) => {
  const work = get(prismicWork, "data", {})
  const tags = get(prismicWork, "tags", [])

  return (
    <Fragment>
      <Helmet
        title={`Hjpaxton portfolio | ${work.title.text}`}
        charSet="utf-8"
        link={[{ rel: "shortcut icon", type: "image/png", href: `${favicon}` }]}
      />
      <Header>
        <BackContainer>
          <Back state={{ filters: get(location, "state.filters", null) }}>
            Back
          </Back>
        </BackContainer>
        {work.header_image.fluid && (
          <HeaderImage
            src={work.header_image.fluid.src}
            srcSet={work.header_image.fluid.srcSet}
          />
        )}
        {!work.header_image.fluid && (
          <ColoredHeader color={work.grid_color}></ColoredHeader>
        )}
        {(work.header_image.fluid || work.grid_color) && (
          <Berries src={berries} />
        )}
      </Header>
      <Container>
        <TitleContainer>
          <Title>{work.title.text}</Title>
          <Underline src={underline} />
        </TitleContainer>
        <TagContainer>
          {!!tags.length && tags.map(tag => <Tag key={tag}>{tag}</Tag>)}
        </TagContainer>
        <Content dangerouslySetInnerHTML={{ __html: work.description.html }} />

        {!!work.links.length && (
          <>
            <LinkTitle>Links</LinkTitle>
            <Links>
              {work.links.map(l => (
                <ExternalLink
                  href={l.link.url}
                  target="__blank"
                  key={l.text.text}
                >
                  {l.text.text} <Icon icon={faChevronRight} />
                </ExternalLink>
              ))}
            </Links>
          </>
        )}
      </Container>

      <WorkNav nav={workNav} />
    </Fragment>
  )
}

export const pageQuery = graphql`
  query WorkBySlug($uid: String!) {
    prismicWork(uid: { eq: $uid }) {
      tags
      data {
        title {
          text
        }
        description {
          html
        }
        links {
          text {
            text
          }
          link {
            url
          }
        }
        header_image {
          fluid {
            src
            srcSet
          }
        }
        grid_color
      }
    }
  }
`

export default Work
