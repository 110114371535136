import React from "react"
import { Link } from "gatsby"

import tw, { styled } from "twin.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"

const Container = styled.footer`
  ${tw`flex justify-between p-6 md:p-10 md:px-26 xl:px-36 md:bg-light-grey`}

  ${({ onlyPrev }) => onlyPrev && tw`justify-start`}
  ${({ onlyNext }) => onlyNext && tw`justify-end`}
`

const NavItem = styled(Link)`
  ${tw`font-header font-semibold text-base md:text-3xl leading-normal`}
`

const NavIcon = styled(FontAwesomeIcon)`
  ${({ position }) => (position === "left" ? tw`mr-2` : tw`ml-2`)}
`

const WorkNav = ({ nav }) => (
  <Container onlyPrev={!nav.next} onlyNext={!nav.prev}>
    {nav.prev && (
      <NavItem to={`/${nav.prev}`}>
        <NavIcon icon={faArrowLeft} position="left" /> Last Project
      </NavItem>
    )}
    {nav.next && (
      <NavItem to={`/${nav.next}`}>
        Next Project <NavIcon icon={faArrowRight} position="right" />
      </NavItem>
    )}
  </Container>
)

export default WorkNav
